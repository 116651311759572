import React, { useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { isDevelopment, FormatDateTime, GET, POST } from './Global';
import { connect, MqttClient } from 'mqtt/dist/mqtt';
import { useNotify } from './Components/useNotify';

const btnCSS = "rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer";

const btnCSS2 = "rounded-md px-2 py-1 text-xs font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer";


function App() {
  const [open, setOpen] = useState(false);
  const [checkedLog, setCheckedLog] = useState<any[]>([]);
  const [client, setClient] = useState<MqttClient | null>(null);
  const [connectStatus, setConnectStatus] = useState<string | null>(null);

  const queryClient = useQueryClient();
  const { data, isLoading, isFetching, refetch, isError, error } = useQuery(['info'], () => {
    var oldDevices = localStorage.getItem("devices") ?? "";
    return GET({ url: '/Web/Guard/BigScreen/Info', data: { d: oldDevices } });
  });

  const mqttConnect = (host: string, mqttOption?: any) => {
    if (client == null) {
      console.log('===Connecting===')
      setConnectStatus('Connecting');
      setClient(connect(host, mqttOption));
    }
  };
  const mqttSub = (topicArr: string[]) => {
    if (client) {
      // const { topic, qos } = subscription;
      client.subscribe(topicArr, {}, (error: any) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
      });
    }
  };
  useEffect(() => {
    if (client) {
      client.on('connect', () => {
        setConnectStatus('Connected');
        const { MQTT: { MQTT_Subscribe } } = data as any;
        console.log(new Date(), 'MQTT_Subscribe', MQTT_Subscribe)
        mqttSub(MQTT_Subscribe);
      });
      client.on('error', (err: any) => {
        setConnectStatus('Error');
        console.warn(new Date(), 'Connection error: ', err);
        client.end();
      });
      client.on('reconnect', () => {
        setConnectStatus('Reconnecting');
        console.log(new Date(), 'Reconnecting')
      });
      client.on('message', (topic: any, message: any) => {
        const payload = { topic, message: message.toString() };
        console.log(new Date(), 'payload', payload)
        setPayload(payload);
      });
    }
  }, [client]);

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, [connectStatus]);

  // User has switched back to the tab
  const onFocus = () => {
    console.log("Tab is focus");
    console.log("connectStatus", connectStatus);
    if (connectStatus !== 'Connected') {
      setTimeout(() => { document.location.href = '/' }, 600);
    }
  };
  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    console.log("Tab is blur");
  };

  const setPayload = ({ topic, message }: any) => {
    if ((topic as string).indexOf('DeviceLog/') === 0) {
      let SN = (topic as string).split('/')[2];
      let rObj = JSON.parse(message);
      rObj = { ...rObj, __push: true }
      const _data = { ...(data as any) };
      for (var i in _data.ChooseDevicesDatas) {
        if (SN === _data.ChooseDevicesDatas[i].Device.SN) {
          let oldIndex = _data.ChooseDevicesDatas[i].LogList.findIndex((t: any) => t.id === rObj.id);
          if (oldIndex < 0) {
            console.log('串联信息')
            _data.ChooseDevicesDatas[i].LogList = [rObj].concat(_data.ChooseDevicesDatas[i].LogList);
          }
          else {
            console.log('替换信息')
            _data.ChooseDevicesDatas[i].LogList[oldIndex] = rObj;
          }
        }
      }
      console.log('update Info Data', _data)
      queryClient.setQueryData('info', () => _data);
    }
    else {
      console.log(`未匹配到topic topic:${topic}`)
    }
  }

  const getCheckedLogItem = (SN: string, LogList: any[]) => {
    var _d = checkedLog.find(t => t.SN == SN);
    if (_d != null) {
      var _log = LogList.find(t => t.id == _d.CheckedLogId);
      if (_log != null) return _log;
    }
    else if (LogList.length > 0)
      return LogList[0];

    return null;
  }

  const [clickTimer, setClickTimer] = useState<any>(null);
  const logItemClick = (_SN: string, _LogId: number) => {
    try { clearTimeout(clickTimer) } catch { }

    console.log(_SN, _LogId)
    var _checkedLog = [...checkedLog];
    var old = _checkedLog.find(t => t.SN === _SN)
    if (old != null) old.CheckedLogId = _LogId;
    else _checkedLog.push({ SN: _SN, CheckedLogId: _LogId })
    setCheckedLog(_checkedLog);

    const _clickTimer = setTimeout(() => { setCheckedLog([]); }, 30 * 1000);
    setClickTimer(_clickTimer);
    // localStorage.setItem("checkedLog", JSON.stringify(_checkedLog));
  }

  const connectStatusColor = () => {
    switch (connectStatus) {
      case 'Connected': return '#00FF00';
      case 'Connecting': return '#0000FF';
      case 'Reconnecting': return '#0000FF';
      case 'Error': return '#FF0000';
    }
    return '#FFF'
  }

  if (isError)
    return (
      <div className='fixed right-0 left-0 top-0 bottom-0 flex flex-col justify-center items-center space-y-3'>
        <div> Err! {`${error}`}
        </div>
        <div className={btnCSS} onClick={() => { document.location.href = '/' }}>刷新</div >
      </div>
    )
  if (isLoading) return <div className='fixed right-0 left-0 top-0 bottom-0 flex justify-center items-center'>Loading</div>

  const { Community, Devices, ChooseDevicesDatas, LT, MQTT: { MQTT_USERID } } = data as any;
  const { id, NickName } = Community;

  //MQTT
  const clientId = MQTT_USERID;
  const username = MQTT_USERID;
  const password = MQTT_USERID;
  const protocol = true ? 'ws' : 'wss';
  const host = '47.109.41.209';
  const port = true ? 8083 : 8084;
  const url = `${protocol}://${host}:${port}/mqtt`
  const options = {
    clientId,
    username,
    password,
    clean: true,
    reconnectPeriod: 3000, // ms
    connectTimeout: 30 * 1000, // ms
  }
  mqttConnect(url, options);
  //MQTT END

  return (
    <div className='flex flex-col h-screen'>
      <div className='flex relative text-3xl justify-center py-3'>
        <div className='text-lg absolute top-0 left-3 h-full flex items-center space-x-2'>通信状态：<span style={{ color: connectStatusColor() }}>{connectStatus}</span></div>
        {NickName}
        <div className='absolute top-0 right-3 h-full flex  items-center space-x-2'>
          <div className=''></div>
          <button
            type="button"
            className={btnCSS}
            onClick={() => {
              document.location.href = '/';
            }}
          >
            刷新
          </button>
          <button
            type="button"
            className={btnCSS}
            onClick={() => {
              setOpen(!open)
            }}
          >
            管理设备
          </button>
        </div>
      </div>

      <div className='flex flex-wrap flex-1 bg-black'>
        {
          ChooseDevicesDatas.map(({ Device: { SN, Name, PropertyAccount, Online, CheWeiInOrOut }, LogList }: any) => {
            var checkedLogItem = getCheckedLogItem(SN, LogList);
            return (
              <div className='flex flex-col w-1/2 h-1/2 p-3'>
                <div className='flex flex-col flex-1  bg-gray-700 p-1 '>
                  <div className=' bg-gray-800 p-1 flex justify-between'>
                    <div>{Online ? '在线' : '离线'}</div>
                    <div>{Name}</div>
                    <div>{SN}</div>
                  </div>
                  <div className='flex flex-1'>
                    <div className='w-1/2 relative flex flex-col space-y-3 p-2 bg-gray-900'>
                      {checkedLogItem != null ?
                        <>
                          {
                            [checkedLogItem].map(
                              ({ id, Title, UpTime, PicData, PicFullData, ElseJson }: any) => {
                                return (
                                  <>
                                    <div className=' relative'>
                                      <img className='w-full' src={PicFullData} alt={Title} />
                                      <img className=' absolute left-0 bottom-0 w-1/3' src={PicData} alt={Title} />
                                    </div>
                                    {LT?.OpenLinTingCheWei == true && <OrderView LT={LT} CheWeiInOrOut={CheWeiInOrOut} ElseJsonData={ElseJson} />
                                    }
                                  </>
                                )
                              }
                            )
                          }
                        </>
                        :
                        <div>暂无任何信息</div>
                      }
                    </div>
                    <div className='p-1 flex-1 relative'>
                      <div className='p-2 absolute top-0 right-0 left-0 bottom-0 overflow-y-auto'>
                        {LogList.map(({ id, Title, Content, UpTime, PicData, PicFullData, ElseJson, __checked, __push }: any, __i: number) => {
                          let __checkedSty = '';
                          if (checkedLogItem != null && checkedLogItem.id === id) __checkedSty = 'bg-blue-700';
                          let _ElseJson = ElseJson ?? '';
                          return (
                            <div className={`p-1 cursor-pointer	 ${__checkedSty}`} onClick={() => logItemClick(SN, id)}>
                              <div className={`flex text-sm`} >
                                <div className='min-w-max w-24'>{FormatDateTime(UpTime)}</div>
                                {__push ? <div className='bg-green-600 mr-1'>推</div> : null}
                                <div>{Title}</div>
                              </div>
                              {/* <div className='text-xs break-all text-gray-400	'>{Content}</div> */}
                              <ElseMsgView ElseJsonData={_ElseJson} Content={Content} />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div className=' bg-gray-800 px-1 py-2 flex'>
                    <OpenDoor SN={SN} />
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>

      {open &&
        <ChooseDevice Devices={Devices} setOpen={setOpen} />
      }

    </div>
  );
}

const ElseMsgView = ({ ElseJsonData, Content }: any) => {

  if (ElseJsonData && ElseJsonData.indexOf("{") === 0) {
    let { User, CheWei, OrderNumber, OrderMessageId } = JSON.parse(ElseJsonData);
    return <div className='flex flex-col text-xs break-all text-gray-400'>
      {OrderNumber && <div>{`订单：${OrderNumber}-${OrderMessageId}`}</div>}
      <div>{`用户：${User}`}</div>
      <div>{`车位：${CheWei}`}</div>
      <div>{`${Content}`}</div>
    </div>
  }
  return null;
}

const OrderView = ({ LT, CheWeiInOrOut, ElseJsonData }: any) => {
  const notify = useNotify();
  const _in = CheWeiInOrOut === 'in';

  const mutation_cancelOrder = useMutation(({ OrderNumber, OrderMessageId }: any) => {
    return POST({ url: '/Web/Guard/BigScreen/CancelOrder', data: { OrderNumber, OrderMessageId } });
  },
    {
      onSuccess: (message: any) => {
        notify.show({ message: message as string, type: 'success' });
      },
      onError: (err: Error) => {
        // console.log('err', err);
        notify.show({ message: `调用失败:${err.message}`, type: 'error' });
      }
    }
  );

  const mutation_payOrder = useMutation(({ OrderNumber, OrderMessageId, Money }: any) => {
    return POST({ url: '/Web/Guard/BigScreen/PayOrder', data: { OrderNumber, OrderMessageId, Money } });
  },
    {
      onSuccess: (message: any) => {
        notify.show({ message: message as string, type: 'success' });
      },
      onError: (err: Error) => {
        // console.log('err', err);
        notify.show({ message: `调用失败:${err.message}`, type: 'error' });
      }
    }
  );

  const cancel = ({ OrderNumber, OrderMessageId }: any) => {
    if (window.confirm('确定要取消订单，取消后如需放行请手动开闸')) {
      mutation_cancelOrder.mutate({ OrderNumber, OrderMessageId });
    }
  }

  const shoufei = ({ OrderNumber, OrderMessageId }: any) => {
    if (window.confirm('确定已经收取现金，确定后会自动开闸放行')) {
      mutation_payOrder.mutate({ OrderNumber, OrderMessageId });
    }
  }

  if (ElseJsonData && ElseJsonData.indexOf("{") === 0) {
    let { User, CheWei, OrderNumber, OrderMessageId } = JSON.parse(ElseJsonData);
    return <div className='flex flex-col text-xs break-all text-gray-400'>
      <div>{`用户：${User}`}</div>
      <div>{`车位：${CheWei}`}</div>
      {OrderNumber && <>
        <div>{`订单：${OrderNumber}-${OrderMessageId}`}</div>
        <div className='flex gap-2 mt-2'>
          {_in ?
            <>
              {LT?.OpenByHand == true && <div className={btnCSS2 + ' bg-red-600'} onClick={() => cancel({ OrderNumber, OrderMessageId })}>退回订单</div>}
            </>
            :
            <>
              {LT?.FreeByHand == true &&
                <>
                  <div className={btnCSS2 + ' bg-blue-600'} onClick={() => shoufei({ OrderNumber, OrderMessageId })}>人工收费</div>
                  <div className={btnCSS2 + ' bg-green-600'} onClick={() => cancel({ OrderNumber, OrderMessageId })}>免费退单</div>
                </>
              }
            </>
          }
        </div>
      </>
      }
    </div>
  }
  return null;
}

const OpenDoor = ({ SN }: any) => {
  // const queryClient = useQueryClient();
  const notify = useNotify();

  const mutation_open = useMutation(() => {
    return POST({ url: '/Web/Guard/BigScreen/Open', data: { SN } });
  },
    {
      onSuccess: (message: any) => {
        notify.show({ message: message as string, type: 'success' });
      },
      onError: (err: Error) => {
        // console.log('err', err);
        notify.show({ message: `调用失败:${err.message}`, type: 'error' });
      }
    }
  );

  return (
    <div className={btnCSS} onClick={() => mutation_open.mutate()}>手动开闸</div>
  )
}

const ChooseDevice = ({ Devices, setOpen }: any) => {

  const [choose, setChoose] = useState<string[]>([])
  useEffect(() => {
    var oldDevices = localStorage.getItem("devices") ?? "";
    if (oldDevices.length > 0) {
      setChoose(oldDevices.split(','));
    }
  }, [])

  const C_Devices = () => {
    var cD = Devices.map((d: any) => {
      let checked = choose.findIndex(t => t == d.SN) >= 0;
      return { ...d, checked };
    })
    return cD;
  }


  return (
    <div className='fixed top-0 left-0 right-0 bottom-0 bg-black/60 flex justify-center items-center'>
      <div className='bg-indigo-900 max-w-xl w-1/3 h-1/3 rounded-md p-3 flex flex-col'>
        {/* <div className=' text-2xl pb-4'>选择设置</div> */}
        <div className='flex text-xl font-bold'>
          <div className='w-9'></div>
          <div className=' w-1/4'>设备编号</div>
          <div className='flex-1'>设备名称</div>
          <div className=' w-1/4'>状态</div>
        </div>

        <div className='flex-1'>
          {C_Devices().map(({ id, Online, SN, Name, checked }: any) => {
            return (
              <div className='flex items-center text-white/80'>
                <div className='w-9 flex items-center'>
                  <input type='checkbox' checked={checked} className='w-5 h-5  '
                    onChange={(e) => {
                      console.log(e.target.checked);
                      var oldIndex = choose.findIndex(t => t == SN);
                      console.log(oldIndex);
                      var _choose = [...choose];
                      if (oldIndex >= 0) { _choose.splice(oldIndex, 1) }
                      else { _choose.push(SN) }
                      setChoose(_choose);
                    }} />
                </div>
                <div className=' w-1/4'>{SN}</div>
                <div className='flex-1'>{Name}</div>
                <div className=' w-1/4'>{Online ? '在线' : '离线'}</div>
              </div>
            )
          })}
        </div>

        <div className='flex justify-end space-x-3'>
          <button
            type="button"
            className={btnCSS}
            onClick={() => {
              setOpen(false)
            }}
          >
            取消
          </button>
          <button
            type="button"
            className={btnCSS}
            onClick={() => {
              localStorage.setItem("devices", choose.join(','));
              document.location.href = '/'
            }}
          >
            确定
          </button>
        </div>
      </div>
    </div>
  )
}

export default App;
